@use '../../variables';

.main_container {
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-family: variables.$font-family;
    width: 60%;
    margin-left: 20%;

    img {
        width: 100%;
        height: auto;
        opacity: 1;
        transition: opacity 1s ease-in-out;
    }

    h2 {
        font-size: 22px;
    }
}

@media (max-width: 1366px) {
    .main_container {
        width: 100%;
        margin-left: 0;
        padding: 0 20px;
    }
}