@use '../../variables';

.main_container {
    display: flex;
    flex-direction: column;
    font-family: variables.$font-family;
    min-height: 40px;
    width: 100%;

    .upper_part {
        height: 40px;
        background-color: variables.$basic_white_color;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-family: variables.$font-family;
        width: 100%;
        gap: 20%;
        font-weight: bold;
        background-color: variables.$black_color;

        .upper_part_content {
            max-width: 1300px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
        }

        .left_side {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50%;

            .marketing_text {
                span {
                    color: variables.$basic_white_color;
                    font-weight: 400;
                }
            }

            .phone {
                a {
                    display: flex;
                    align-items: center;
                    width: 137px;
                    text-decoration: none;
                }


                option {
                    background-color: variables.$basic_grey_color;
                }
            }

        }

        .right_side {
            display: flex;
            justify-content: flex-end;
            width: max-content;
            gap: 20px;
            align-items: center;
            width: 360px;

            span {
                cursor: pointer;
                color: variables.$basic_white_color;
            }

            .blured {
                opacity: 0.5;
                cursor: auto;
            }
        }
    }

    .bottom_part {
        display: flex;
        align-items: center;
        height: 115px;
        background-color: variables.$basic_white_color;
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;

        .logo_wrapper {
            width: 12%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
                max-height: 100%;
                height: auto;
                cursor: pointer;

            }
        }

        .links_container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-family: variables.$second_font_family;
            font-weight: bold;
            text-transform: uppercase;
            width: 65%;
            margin-left: 3%;

            a {
                text-decoration: none;
                color: variables.$cart_grey_color;
                margin-right: 5%;
                font-size: 17px;
                font-weight: 100;
                padding: 2%;

                &:hover {
                    color: variables.$app_light_green_color;
                    background-color: variables.$app_grey_color;
                }

                &:last-child {
                    margin-right: 0;
                }
            }



            .join_us {
                color: variables.$app_light_green_color;
                display: flex;
                align-items: center;
                margin-left: 4%;

                p {
                    margin: 0;
                }

                img {
                    width: 25px;
                    height: 25px;
                    margin-right: 7px;
                    cursor: pointer;
                }

            }

            .about {
                color: variables.$app_light_green_color;
            }
        }

        .icons_container {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            width: 17%;


            .user_details {
                display: flex;
                align-items: center;
                width: 25px;

                img {
                    width: 25px;
                    height: 25px;
                }
            }

            .cart {
                display: flex;
                align-items: center;

                a {
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .quantity {
                        z-index: 1;
                        position: sticky;
                        width: 17px;
                        height: 17px;
                        transform: translate(120%, -80%);
                        margin-top: 15%;
                        background-color: variables.$app_light_green_color;
                        border-radius: 50%;
                        text-align: center;
                        justify-self: flex-start;


                        p {
                            font-weight: bold;
                            color: variables.$basic_white_color;
                            font-size: 11px;
                        }
                    }

                    img {
                        width: 23px;
                        height: 23px;
                        filter: variables.$convert_to_blue_color;
                        cursor: pointer;
                    }
                }
            }

            .cart_worth {
                height: 25px;
                line-height: 25px;
                text-align: center;
                margin: 0 3%;
                font-weight: bold;
                font-size: 12px;
                color: variables.$basic_black_color;
                font-family: variables.$second_font_family;
                text-decoration: none;
            }
        }

        .hamburger_container {
            display: none;
            margin-left: 10px;

            .hamburger {
                width: 2rem;
                display: flex;
                justify-content: space-between;
                flex-flow: column nowrap;
                z-index: 10;

                .bar {
                    width: 2rem;
                    height: 0.25rem;
                    border-radius: 10px;
                    background-color: variables.$app_dark_green_color;
                    transform-origin: 1px;
                    transition: all 0.3s linear;
                }

                .bar2 {
                    margin: 5px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .main_container {
        width: 100%;

        .upper_part {

            .upper_part_content {
                padding: 0 20px;
            }
        }

        .bottom_part {
            padding: 0 20px;

            .links_container {
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .main_container {
        .upper_part {
            .upper_part_content {
                font-size: 12px;
            }

            .left_side {
                .phone {
                    a {
                        width: 90px;
                    }
                }
            }
        }

        .bottom_part {
            justify-content: space-between;
            margin-bottom: 10px;

            .logo_wrapper {
                width: 60%;
                order: 2;
            }

            .links_container {
                display: none;

                &.open {
                    display: flex;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 9;
                    margin-left: 0;
                    width: 100%;
                    height: 100vh;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: variables.$mob_menu_bg;
                    color: #fff;

                    a {
                        color: #fff;
                        margin-right: 0;
                        font-size: 18px;
                        padding: 10px 0;

                        &.join_us,
                        &.about {
                            color: #fff;
                            margin: 0;
                        }

                        img {
                            filter: variables.$convert_to_white_color;
                        }
                    }
                }
            }

            .icons_container {
                width: 20%;
                order: 3;
            }

            .hamburger_container {
                display: block;
                order: 1;
                width: 20%;

                &.hamburger_active {
                    position: fixed;
                    top: 0;
                    right: 0;
                    margin-top: 20px;
                    margin-right: 20px;
                    z-index: 10;
                }

                .menu_open {
                    .bar {
                        transform-origin: 3px;
                        background-color: #fff;
                    }

                    .bar1 {
                        transform: rotate(45deg);
                    }

                    .bar2 {
                        transform: translateX(100%);
                        opacity: 0;
                    }

                    .bar3 {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {

    .phone {
        margin-right: 10px;
    }

    .phone,
    .email {
        a {
            width: auto !important;
        }

        span {
            display: none;
        }
    }
}